import "bootstrap";
import * as bootstrap from "bootstrap";
import { CountUp } from "./lib/countup";
import initTeamMembers from "./components/TeamMembers";
import observeIntersection from "./components/IntersectionObserver";
import statObserver from "./components/StatObserver";
window.bootstrap = bootstrap;

let Main = {
  init: async function () {
    initTeamMembers();

    let clientLogos = document.querySelector(".homepage-intro__client-logos");
    if (clientLogos) {
      clientLogos = new Flickity(clientLogos, { watchCSS: true });
    }

    observeIntersection();
    statObserver(CountUp);
  },
};

Main.init();
