export default function initTeamMembers() {
  const teamMembersGrid = document.querySelector(".team-members__grid");
  if (teamMembersGrid) {
    const teamMembers = teamMembersGrid.querySelectorAll(
      '[data-bs-toggle="popover"]'
    );
    const popoverList = [...teamMembers].map((member) => {
      new bootstrap.Popover(member, {
        html: true,
        title: member.querySelector(".team-members__member__name").textContent,
        content: member.querySelector(".team-members__member__bio").innerHTML,
        trigger: "click",
        fallbackPlacements: ["right", "left", "bottom", "top"],
        boundary: teamMembersGrid,
        animation: false,
      });
    });
    // hide older popovers
    document.addEventListener("show.bs.popover", () => {
      for (let el of document.querySelectorAll(".popover")) {
        el = document.querySelector(`[aria-describedby="${el.id}"]`);
        bootstrap.Popover.getInstance(el).hide();
      }
    });
    // add acive class for open ones
    document.addEventListener("shown.bs.popover", () => {
      for (let el of document.querySelectorAll(".popover")) {
        document
          .querySelector(`[aria-describedby="${el.id}"]`)
          .classList.add("active");
      }
    });
    // remove active class for popovers that's been closed
    document.addEventListener("hidden.bs.popover", () => {
      for (let el of document.querySelectorAll(
        ".team-members__member.active:not([aria-describedby])"
      )) {
        el.classList.remove("active");
      }
    });
    // add ability to close via button
    document.addEventListener("click", (e) => {
      let el = e.target;
      if (el.classList.contains("team-members__member__bio__close")) {
        el = document.querySelector(
          `[aria-describedby="${el.closest(".popover").id}"]`
        );
        bootstrap.Popover.getInstance(el).hide();
      }
    });
  }
}
