export default function statObserver(CountUp) {
  const counters = document.querySelectorAll("[data-count]");

  if (counters.length > 0) {
    const config = { attributes: true, childList: false, subtree: false };
    const observer = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        console.log(mutation, mutation.target.dataset.countTo);
        if (
          mutation.attributeName === "class" &&
          mutation.target.classList.contains("in-view")
        ) {
          let countUp = new CountUp(
            mutation.target,
            mutation.target.dataset.countTo
          );
          countUp.start();

          // disconnect to prevent multiple animations
          observer.disconnect();
        }
      }
    });

    for (let counter of counters) observer.observe(counter, config);
  }
}
