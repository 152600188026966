export default function observeIntersection() {
  const els = document.querySelectorAll(".watch");

  if (els.length > 0) {
    let observer = new IntersectionObserver(handleIntersect);
    for (let el of els) observer.observe(el);
  }

  function handleIntersect(entries, observer) {
    for (let entry of entries) {
      if (entry.isIntersecting) entry.target.classList.add("in-view");
    }
  }
}
